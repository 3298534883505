<script>

  import { gsap } from "gsap";
  import { SplitText } from "gsap/SplitText.js";
  import Vue from "vue";
  import p5 from "p5";
  import headerSketch from "./sketch";

  gsap.registerPlugin(SplitText);

  export default {
    data: ()=>({
      contentHeight: 0,
      currentSlide: 1,
      a11yProgress: 0,
      tl: gsap.timeline({
        paused: true,
        repeat: -1
      }),
      sceneLength: 9.5,
      resizeObserver: null,
      paused: false,
      development: false // Pause animation, and display first slide
    }),
    computed: {
      items: function() {
        return this.$refs.itemGroup.querySelectorAll('.frontpage-item');
      }
    },
    props: {
      heros: {
        type: Array,
        default: function() {
          return [];
        }
      }
    },
    watch: {
      paused(value, oldValue) {
        if(value) {
          this.tl.pause();
        } else {
          this.tl.play();
        }
      }
    },
    async mounted() {

      await Vue.nextTick;

      // Set + Observe Content Height

      this.resizeObserver = new ResizeObserver(this.setContentHeight);
      this.items.forEach((el) => this.resizeObserver.observe(el));
      this.setContentHeight();

      if(this.development) return;

      setTimeout(() => { // Split text having issue getting height, temporary solution
      
      const self = this;

        this.items.forEach((el, index) => {

          let nestedTL = gsap.timeline({
            paused: false,
            onStart: () => this.currentSlide = index + 1
          });

          let sceneLabel = `scene_${index}`;

          let eyebrow =     el.querySelector('.frontpage-eyebrow');
          let heading =     el.querySelector('.frontpage-heading');
          let subheading =  el.querySelector('.frontpage-subheading');
          let copy =        el.querySelector('.frontpage-copy');
          let cta =         el.querySelector('.frontpage-cta');

          let splitSubheading = new SplitText(subheading, {type: "lines"});

          this.tl.set(
            el,
            {autoAlpha: 1}
          );

          nestedTL.fromTo(
            eyebrow,
            {opacity: 0, yPercent: 100},
            {opacity: 1, yPercent: 0, duration: 1, ease: 'power1.out'},

          );

          nestedTL.fromTo(
            heading,
            {yPercent: 100},
            {yPercent: 0, duration: 1.5, ease: 'power2.out'},
            '-=.75'
          );

          nestedTL.fromTo(
            splitSubheading.lines,
            {opacity: 0},
            {opacity: 1, duration: 1, stagger: .25},
            '-=.5'
          );

          nestedTL.fromTo(
            copy,
            {opacity: 0, yPercent: 50},
            {opacity: 1, yPercent: 0, duration: 1, ease: 'power3.out'},
            '-=.75'
          );

          nestedTL.fromTo(
            cta,
            {opacity: 0, yPercent: 80},
            {opacity: 1, yPercent: 0, duration: .25, ease: 'power2.out'},
            '-=.75'
          );

          this.tl.add(sceneLabel);

          this.tl.fromTo(
            this.$refs.statusBar,
            {xPercent: -100},
            {
              xPercent: 0,
              duration: this.sceneLength,
              ease: 'none',
              onUpdate: function() {
                self.a11yProgress = Math.round(this.progress() * 100);
              }
            },
            `scene_${index}`
          );

          this.tl.add(nestedTL, sceneLabel);

          this.tl.to(
            el,
            {autoAlpha: 0, duration: 1}
          );

        });

        this.tl.play();

      }, 0);

      let myHeader = new p5(headerSketch);

    },
    methods: {
      setContentHeight() {
        let newHeight = 0;
        this.items.forEach((el) => {
          if (el.getBoundingClientRect().height > newHeight) {
            newHeight = el.getBoundingClientRect().height;
          }
        });
        this.contentHeight = newHeight;
      }
    }
  }

</script>

<template>

  <div class="page-header frontpage">
    <div class="container frontpage-container">
      <div class="row frontpage-row">
        <div class="col-sm-16 col-md-16 col-lg-8 frontpage-column">
          <div class="frontpage-content">

            <div
              class="frontpage-item-group"
              ref="itemGroup"
              :style="{height : `${contentHeight}px`}"
            >
              <div class="frontpage-item" v-for="(hero, index) in heros">
                <div class="frontpage-item-content">
                  <h2 class="frontpage-title">
                    <span class="frontpage-eyebrow">{{hero.line1}} </span>
                    <span class="frontpage-heading-mask">
                      <span class="frontpage-heading">{{hero.line2}}</span>
                    </span>
                    <span class="frontpage-subheading">{{hero.line3}}</span>
                  </h2>
                  <p class="mb-none frontpage-copy">{{hero.blurb}}</p>
                  <div class="frontpage-cta">
                    <a :href="hero.url" class="btn-cta">
                      <span class="arrow-icon" aria-hidden="true"></span>
                      {{hero.label}}
                      <span class="circle-icon" aria-hidden="true">
                        <span class="icon-carrot-circle off"></span>
                        <span class="icon-carrot-circle-solid on"></span>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-16 col-lg-8 frontpage-presentation-column">
          <div class="frontpage-presentation">
            <div id="myCanvas" class="frontpage-canvas"></div>
          </div>
        </div>
      </div>
      <div class="frontpage-actions">
        <div class="frontpage-indicator">
          <div class="frontpage-nav">
            <span class="current">0{{currentSlide}}</span>
            <span class="separator" role="separator">/</span>
            <span class="total">03</span>
          </div>

          <button @click="paused = !paused">
            <span class="circle-icon hover-icon" aria-hidden="true">
              <span class="icon-play-circle-solid on" v-if="paused"></span>
              <span class="icon-pause-circle-solid on" v-else></span>
              <span class="icon-play-circle off" v-if="paused"></span>
              <span class="icon-pause-circle off" v-else></span>
            </span>
            <span class="sr-only" v-if="paused">Play slides</span>
            <span class="sr-only" v-else>Pause slides</span>
          </button>

        </div>
        <div class="frontpage-scroll">
          <div class="frontpage-scroll-label">
            <span class="small display-block">Scroll down</span>
          </div>
        </div>
      </div>
    </div>
    <div
      class="frontpage-status"
      role="progressbar"
      :aria-label="`Slide ${currentSlide} Progress`"
      :aria-valuenow="a11yProgress"
    >
      <div class="fill" aria-hidden="true" ref="statusBar"></div>
    </div>
  </div>
  

</template>
