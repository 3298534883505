<script>
  export default {
    mounted: function() {
      window.addEventListener('scroll', this.handleScroll)
    },
    methods: {
      handleClick: function() {
        window.scrollTo(0,0)
      },
      handleScroll() {
        let button = document.getElementById('back-to-top-button')
        if (window.scrollY > window.innerHeight) {
          button.classList.add('show')
        } else {
          button.classList.remove('show')
        }
      }
    }
  }
</script>

<template>
  <div class="back-to-top-wrapper">
    <button @click=handleClick id="back-to-top-button" class="back-to-top-button" aria-polite='true'>
      <span class="icon-arrow-forward-small"></span>
      <span class="sr-only">Back to top</span>
    </button>
  </div>
</template>