<script>
  import _ from 'lodash';
  import axios from 'axios';

  const dropDownFilters = ['offices', 'services', 'industries', 'languages', 'titles', 'degrees', 'concentrations', 'schools'];

  export default {
    name: "people-search",
    props: {
      config: {
        type: Object,
        default: function() {
          return {};
        }
      },
      authToken: {

      }
    },
    components: {
    },
    data: function() {
      let filters = {
        letter: null,
        q: null
      };
      for (var i = 0; i < dropDownFilters.length; i++) {
        filters[dropDownFilters[i]] = null;
      }
      return {
        filters: filters,
        searchResults: [],
        hitsPerPage: null,
        numberOfPages: null,
        numberHits: null,
        isFetching: false
      };
    },
    watch: {
      filters: {
        deep: true,
        immediate: true,
        handler: function(newVal) {
          this.performSearch();
        }
      }
    },
    computed: {
      searchParams: function() {
        let params = {
          q: this.q,
          letter: this.letter
        };
        for (var i = 0; i < dropDownFilters.length; i++) {
          let filterVal = this.filters[dropDownFilters[i]];
          if (filterVal) {
            params[dropDownFilters[i]] = filterVal.name;
          }
        }
        return params;
      },
      alphabet: function() {
        return "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
      },
      offices: function() {
        if (this.config && this.config.filters.offices) {
          return this.config.filters.offices;
        }
        return [];
      },
      languages: function() {
        if (this.config && this.config.filters.languages) {
          return this.config.filters.languages;
        }
        return [];
      },
      services: function() {
        if (this.config && this.config.filters.services) {
          return this.config.filters.services;
        }
        return [];
      },
      industries: function() {
        if (this.config && this.config.filters.industries) {
          return this.config.filters.industries;
        }
        return [];
      },
      titles: function() {
        if (this.config && this.config.filters.titles) {
          return this.config.filters.titles;
        }
        return [];
      },
      schools: function() {
        if (this.config && this.config.filters.schools) {
          return this.config.filters.schools;
        }
        return [];
      },
      degrees: function() {
        if (this.config && this.config.filters.degrees) {
          return this.config.filters.degrees;
        }
        return [];
      },
      concentrations: function() {
        if (this.config && this.config.filters.concentrations) {
          return this.config.filters.concentrations;
        }
        return [];
      },
      admissions: function() {
        if (this.config && this.config.filters.admissions) {
          return this.config.filters.admissions;
        }
        return [];
      },
      isFilterSet: function() {
        if (this.filters.query || this.filters.letter) {
          return true;
        }
        for (var i = 0; i < dropDownFilters.length; i++) {
          if (this.filters[dropDownFilters[i]]) {
            return true;
          }
        }
        return false;
      }
    },
    methods: {
      resetFilters: function() {
        this.filters.q = null;
        this.filters.letter = null;
        for (var i = 0; i < dropDownFilters.length; i++) {

          if (this.filters[dropDownFilters[i]]) {
            this.filters[dropDownFilters[i]] = null;
          }
        }
      },
      selectLetter: function(letter) {
        this.filters.letter = letter;
      },
      selectOffice: function(office) {
        this.filters.offices = office;
      },
      selectLanguage: function(language) {
        this.filters.languages = language;
      },
      selectService: function(service) {
        this.filters.services = service;
      },
      selectIndustry: function(industry) {
        this.filters.industries = industry;
      },
      selectTitle: function(title) {
        this.filters.titles = title;
      },
      selectSchool: function(school) {
        this.filters.schools = school;
      },
      selectDegree: function(degree) {
        this.filters.degrees = degree;
      },
      selectConcentration: function(concentration) {
        this.filters.concentrations = concentration;
      },
      selectAdmission: function(admission) {
        this.filters.admissions = admission;
      },
      performSearch: function() {
        let self = this;
        let params = this.searchParams;
        let headers = {
          'Content-Type': 'application/json'
        };
        this.isFetching = true;
        axios.get("/people/search", {
          params: params,
          headers: headers
        }).then(response => {
          console.log("Got response from single fetch", response);
          self.searchResults = response.data.hits;
          self.hitsPerPage = response.data.hitsPerPage;
          self.numberOfPages = response.data.nbPages;
          self.numberHits = response.data.nbHits;
        }).catch((error) => {
          console.log("Error is ", error);
          self.searchResults = [];
          throw error;
        })
        .finally(() => {
          console.log("Finally block")
          this.isFetching = false;
        });;
      }
    }
  }

</script>

<template>
  

  <div>
    <p>
      <span>
        <a @click="selectLetter(letter)" v-for="letter in alphabet" :key="letter">{{letter}}</a>
      </span>
    </p>
    <input v-model="filters.q" type="text" placeholder="Name and/or keywords">
    <a @click="resetFilters">Clear Search</a>
    <collection-select :options="offices" v-model="filters.offices" @change="selectOffice" placeholder="Filter by Office"></collection-select>
    <collection-select :options="languages" v-model="filters.languages" @change="selectLanguage" placeholder="Filter by Language"></collection-select>
    <collection-select :options="industries" v-model="filters.industries" @change="selectIndustry" placeholder="Filter by Industry"></collection-select>
    <collection-select :options="services" v-model="filters.services" @change="selectService" placeholder="Filter by Service"></collection-select>
    <collection-select :options="titles" v-model="filters.titles" @change="selectTitle" placeholder="Filter by Title"></collection-select>
    <collection-select :options="schools" v-model="filters.schools" @change="selectSchool" placeholder="Filter by School"></collection-select>
    <collection-select :options="degrees" v-model="filters.degrees" @change="selectDegree" placeholder="Filter by Degree"></collection-select>
    <collection-select :options="concentrations" v-model="filters.concentrations" @change="selectConcentration" placeholder="Filter by Area of Study"></collection-select>
    <collection-select :options="admissions" v-model="filters.admissions" @change="selectAdmission" placeholder="Filter by Admission"></collection-select>
    
    <div v-if="!isFilterSet">
      <slot name="pre-results-slots"></slot>
    </div>
    Hits Per Page {{hitsPerPage}}<br/>
    Number of Hits {{numberHits}}<br/>
    Number of Pages {{numberOfPages}}<br/>
    <div v-for="(result, index) in searchResults" :key="'result-' + index">
      <people-search-result-cell :person="result"></people-search-result-cell>
    </div>
  </div>

</template>

<style scoped>
  .wrap-search {
    display: flex;
    justify-content: center;
  }
  .search-card {
    width: 1248px;
    padding: 30px 90px;
    border: 1px solid #f2f2f2;
  }
  p.search-title {
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
  }
  p.search-letters {
    width: 100%;
  }
  p.search-letters span {
    display: flex;
    justify-content: space-around;
  }
  p.search-letters span a {
    font-size: 24px;
    color: #001C34;
    transition: all 1s;
    padding: 5px;
    border-radius: 20px;
  }
  p.search-letters span a:hover {
    cursor: pointer;
    color: #ccc;
    background: #001C34;
  }
  .search-name {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 0 0 30px 0;
    flex: 1 0;
  }
  .search-name input {
    border-bottom: 4px solid #E9F0F2;
    flex: 1;
    padding-bottom: 15px;
  }
  .vs__dropdown-toggle {
    display: flex !important;
  }

</style>