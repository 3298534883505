<script>
  export default {
    data: ()=>({
      peopleRows: null,
      buttonHidden: false,
    }),
    props: {
      label: String,
      default: "Show more"
    },
    mounted: function() {
       this.rows = document.getElementById("results-row");
    },
    methods: {
      handleClick: function() {
        this.rows.classList.toggle("show-all");
        this.buttonHidden = true;
      }
    }
  }
</script>

<template>
  <button @click=handleClick id="show-more-button" class="btn-showmore" v-if="!buttonHidden">
    <span>{{this.label}}</span>
  </button>
</template>
