var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{class:_vm.classname,attrs:{"aria-label":_vm.label}},_vm._l((_vm.menuItems),function(item,index){return _c('li',[_c('a',{class:{active : _vm.section == item.key},attrs:{"href":item.url,"aria-expanded":(_vm.activeSubnav == item.key) ? 'true' : (item.dropDownType != 'none') ? 'false' : null,"aria-haspopup":(item.dropDownType != 'none') ? 'menu' : null,"aria-controls":(item.dropDownType != 'none') ? 'subMenu' : null},on:{"mouseenter":function($event){return _vm.menuEnter({
        idx : index,
        item : item
      }, $event)},"mouseleave":function($event){return _vm.menuLeave({
        idx : index,
        item : item
      }, $event)},"click":function($event){return _vm.menuClick({
        idx : index,
        item : item
      }, $event)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.menuKeydown({
        idx : index,
        item : item
      }, $event)}}},[_c('span',{ref:"navElement",refInFor:true},[_vm._v(_vm._s(item.title))])])])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }