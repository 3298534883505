<script>
  export default {
  }
</script>
<template>
  <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20C4.477 20 0 15.523 0 10ZM10 2C5.582 2 2 5.582 2 10C2 14.418 5.582 18 10 18C14.418 18 18 14.418 18 10C18 5.582 14.418 2 10 2Z" fill="currentColor"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.5355 6.46449C13.9259 6.85481 13.9259 7.48838 13.5355 7.8787L11.4142 10L13.5355 12.1213C13.9259 12.5117 13.9259 13.1452 13.5355 13.5356C13.1452 13.9259 12.5116 13.9259 12.1213 13.5356L10 11.4142L7.87868 13.5356C7.48836 13.9259 6.85479 13.9259 6.46447 13.5356C6.07414 13.1452 6.07414 12.5117 6.46447 12.1213L8.58579 10L6.46447 7.8787C6.07414 7.48838 6.07414 6.85481 6.46447 6.46449C6.85479 6.07417 7.48836 6.07417 7.87868 6.46449L10 8.58581L12.1213 6.46449C12.5116 6.07417 13.1452 6.07417 13.5355 6.46449Z" fill="currentColor"/>
  </svg>

</template>
