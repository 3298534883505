<script>
  export default {
  }
</script>
<template>
  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10 0.799194C4.477 0.799194 0 5.27619 0 10.7992C0 16.3222 4.477 20.7992 10 20.7992C15.523 20.7992 20 16.3222 20 10.7992C20 5.27619 15.523 0.799194 10 0.799194ZM13.5355 8.67787C13.9259 8.28755 13.9259 7.65398 13.5355 7.26366C13.1452 6.87334 12.5116 6.87334 12.1213 7.26366L10 9.38498L7.87868 7.26366C7.48836 6.87334 6.85479 6.87334 6.46447 7.26366C6.07414 7.65398 6.07414 8.28755 6.46447 8.67787L8.58579 10.7992L6.46447 12.9205C6.07414 13.3108 6.07414 13.9444 6.46447 14.3347C6.85479 14.7251 7.48836 14.7251 7.87868 14.3347L10 12.2134L12.1213 14.3347C12.5116 14.7251 13.1452 14.7251 13.5355 14.3347C13.9259 13.9444 13.9259 13.3108 13.5355 12.9205L11.4142 10.7992L13.5355 8.67787Z" fill="currentColor"/>
</svg>


</template>
