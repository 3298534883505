<script>

  import { state } from './state';
  import office from './office';
  import officesDropdown from './offices-dropdown.vue';

export default {
    components: {
      'office': office,
      'offices-dropdown': officesDropdown,
    },
    props: {
      offices: {
        type: Array
      },
      subhed: {
        type: String
      },
      blurb: {
        type: String
      }
    },
    data: function() {
      return {
        mobileActive: false,
        state: state
      };
    },
    mounted: function() {
     document.addEventListener('click', function(e) {
      if(!!e.target.closest('.page-nav-element')) return;
      if(document.body.classList.contains('office-dropdown-expanded')) {
        this.mobileActive = !this.mobileActive;
        document.body.classList.remove('office-dropdown-expanded');
      }
     }.bind(this));
    },
    methods: {
      clickHandler: function() {
       this.mobileActive = !this.mobileActive;
       if(this.mobileActive) {
          document.body.classList.add('office-dropdown-expanded');
       } else {
          document.body.classList.remove('office-dropdown-expanded');
       }
      },
      hoverOnOffice: function(officeID) {
        if(state.isTouch) {
          return;
        }
        this.hoveredOffice = officeID;
      },
      hoverOffOffice: function() {
        if(state.isTouch) {
          return;
        }
        this.hoveredOffice = null;
      }
    }
  }

</script>

<template>

<div class="office-landing" id="officeLanding">
  <header>
    <div class="page-navigation office-landing-header">
      <div class="container">
        <div class="row">
          <div :class="[{ 'col-sm-16': true }, blurb ? 'col-lg-8' : '']">
            <h2 class="mb-none">{{subhed}}</h2>
          </div>
          <div class="col-sm-16 col-lg-8 blurb-column">
            <p class="mb-none" v-html="blurb"></p>
            <div class="display-lg-none">
              <offices-dropdown :mobile-active='mobileActive' :offices='offices' :click-handler='clickHandler'></offices-dropdown>
            </div>

          </div>
        </div>
      </div>
    </div>
  </header>

  <section class='container'>
    <div class='list'>
      <div class='dropdown-wrapper display-none display-lg-block'>
        <div class='row'>
          <div class="col-sm-16 col-lg-8">
            <offices-dropdown :mobile-active='mobileActive' :offices='offices' :click-handler='clickHandler'></offices-dropdown>
          </div>
        </div>
      </div>
      <div class="row">
        <office
          v-for="office in offices"
          :office="office"
          :key="office.id"
        />
      </div>
    </div>
  </section>

</div>

</template>
