<script>
  import I18nMixin from 'mixins/i18n-mixin';
  var i18nMixin = I18nMixin({});
  import { state } from './state';

  export default {
    data: ()=>({
      mobileActive: false,
      state: state
    }),
    mixins: [i18nMixin],
    props: {
      current: String,
      overview: String,
      insights: String,
      showInsights: {
        type: Boolean, 
        default: true
      }
    }
  }

</script>

<template>

  <div class="bio-select-element">
    <button
      class="h2 bio-select-trigger strong"
      @click="mobileActive =! mobileActive"
      :class="{active: mobileActive}"
    >
      {{current}}
      <span class="icon-arrow-thin"></span>
    </button>
    <div class="bio-select-list" :class="{active: mobileActive}">
      <ul class="bio-select-popup">
        <li class="selected" v-if="overview">
          <a :href="overview" v-html="translationFor('people.overview')"></a>
        </li>
        <li v-if="insights && showInsights">
          <a :href="insights" v-html="translationFor('people.insights')"></a>
        </li>
      </ul>
    </div>
  </div>

</template>