<script>

  import { state } from './state';

  export default {
    props: {
      level: {type: Number},

      hed: null,
      subhed: null,
      blurb: null,
      childLinks: {
        type: Array,
        default: function() {
          return [];
        }
      }
    },
    data: ()=>({
      mobileActive: false,
      state: state,
      currentNode: null,
    }),
    computed: {
      isMobileMenu: function() {
        return this.state.menu == 'mobile';
      },
      currentLevel: function() {
        if (!this.currentNode) {
          return 1;
        } else {
          //
          return 2;
        }
      }
    },
    methods: {
      nodeLink: function(node) {
        if (node) {
          return node.url
        }
        return null;
      },
      nodeText: function(node) {
        if (node) {
          return node.title;
        }
        return null;
      },
      nodeHasChildren: function(node) {
        if (node) {
          return !_.isEmpty(node.children);
        }
        return false;
      },
      nodeChildren: function(node) {

      }
    }

  }

</script>

<template>

  <nav class="page-navigation">
    <div class="container">
      <div class="row">
        <div class="col-sm-16 col-lg-8">
          <h2>{{subhed}}</h2>
          <p class="mb-none">{{blurb}}</p>
        </div>
        <div class="col-sm-16 col-lg-8">
          <div class="page-nav-element">
            <button
              class="page-nav-trigger display-lg-none"
              @click="mobileActive =! mobileActive"
              :class="{active: mobileActive}"
            >
              {{name}}
              <span class="icon-carrot-down"></span>
            </button>
            <div class="page-nav-list" :class="{active: mobileActive}">
              <ul>
                <li v-for="(child, index) in childLinks" :key="'child-' + index">
                  <a :href="nodeLink(child)">{{nodeText(child)}}</a>
                </li>
                <li v-if="isMobileMenu" v-for="(child, index) in childLinks" :key="'mobile-child-' + index">
                  <a :href="nodeLink(child)">{{nodeText(child)}}</a>
                </li>
              </ul>
            </div>
          </div>

        </div>
      </div>
    </div>
  </nav>

</template>
