import _ from 'lodash';
export default function(options) {
  return {
    props: {
      locale: {
        type: String
      },
      translations: {
        type: Object
      }
    },
    computed: {
    },
    methods: {
      translationFor: function(key) {
        console.log("Looking for translations for ", key);
        console.log("Returning value ", _.first(_.at(this.translations, key)));
        return _.first(_.at(this.translations, key));

      }
    }
  }
}
