<script>

  import { state } from '../state';

  const alphabet = "abcdefghijklmnopqrstuvwxyz".split('');

  export default {
    props: {
      subnavData: {type: Object},
      frontendUrl: null
    },
    data: ()=>({
      appState: state,
      queryPeople: '',
      alphabet: alphabet,
      people: []
    }),
    methods: {
      subMenuHover(state) {
        if(this.appState.touch == 'isTouch' || this.appState.menu == 'mobile') {
          return;
        }
        this.$emit('subMenuActive', state);
      },
      subMenuClose() {
        this.queryPeople = '';
        this.$emit('subMenuActive', false);
      },
      clearQuery() {
        this.queryPeople = '';
        this.$refs.peopleInput.focus();
      },
      submitSearch() {
        window.location = `/our-people/?q=${this.queryPeople}`
      },
      onPeopleSearch: _.debounce(function() {
        this.search(this.queryPeople);
      }, 800),
      search: function(q) {
        //console.log("Searching for ", q);
        let self = this;
        let headers = {
          'Content-Type': 'application/json'
        };
        // console.log("Front end url ", self.frontendUrl);
        this.$http.get("/team/auto-suggest/", {
          params: {
            q: q
          },
          baseURL: self.frontendUrl,
          headers: headers
        }).then((d) => {
          if(q == "") { // If it's an empty string empty
            self.people = [];
          } else {
            self.people = d.data.data;
          }
          //console.log("People are ", self.people);
          return d;
        }).catch(function (error) {
          if (error.response) {
            // console.log(error.response.data);
            // console.log(error.response.status);
            // console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            // console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            // console.log('Error', error.message);
          }
          // console.log(error.config);
        });
      }
    },
    computed: {
      closeLabel() {
        return (this.appState.menu == 'mobile') ? 'Main menu' : 'close menu';
      },
      peopleSearchUrl: function() {
        return "/our-people/?q=" + this.queryPeople;
      }
    }
  }

</script>

<template>

  <!-- Hover Restore -->
  <div class="sub-menu">

    <div class="container" @mouseenter="subMenuHover(true)" @mouseleave="subMenuHover(false)" >
      <div class="row">

        <div class="sub-menu-intro col-sm-16 col-lg-4">

          <button class="btn-back" @click="subMenuClose()">
            {{closeLabel}}
            <span class="btn-close display-none display-lg-flex" aria-hidden="true"><span class="icon-close-circle"></span></span>
          </button>

          <p class="sub-menu-description" v-if="subnavData.description">{{subnavData.description}}</p>

          <a :href="subnavData.url ? subnavData.url : '#'" class="btn-cta large">
            <span class="arrow-icon" aria-hidden="true"></span>
            {{subnavData.title}}
          </a>

        </div>

        <section class="sub-menu-people col-sm-16 col-lg-11" v-if="subnavData.dropDownType == 'peoplepicker'" aria-label="Search For People">

          <form @submit.prevent="submitSearch">
            <label for="menuPeopleSearch">Name and/or Keywords</label>
            <div class="sub-menu-people-field">
              <button aria-label="Submit Search" class="sub-menu-submit">
                <span class="icon-search-bold" aria-hidden="true"></span>
              </button>
              <input
                type="text"
                autocomplete="off"
                placeholder="Enter name or keyword"
                id="menuPeopleSearch"
                @keydown="onPeopleSearch"
                class="sub-menu-input"
                v-model="queryPeople"
                ref="peopleInput"
              />
              <button aria-label="Reset Search" class="btn-close sub-menu-reset" type="reset" v-show="queryPeople.length > 0" @click="clearQuery">
                <span class="icon-close-circle" aria-hidden="true"></span>
              </button>
            </div>
          </form>

          <div class="sub-menu-people-directory" v-if="people.length == 0 || queryPeople == ''">
            <p class="mb-none">Search by last name</p>
            <ol class="sub-menu-people-directory-list">
              <li v-for="letter in alphabet" :key="'letter-' + letter">
                <a :href="'/our-people/' + letter + '/'">{{letter.toUpperCase()}}</a>
              </li>
            </ol>
          </div>

          <ul class="people-results-list" v-if="people.length > 0 && queryPeople != ''">
            <li class="people-results-all">
              <a :href="peopleSearchUrl">See all results for “{{queryPeople}}”</a>
            </li>
            <li v-for="(p, index) in people" :key="'person-' + index">
              <a :href="p.url">{{p.name}}</a>
            </li>
          </ul>

        </section>

        <nav class="sub-menu-nav col-sm-16 col-lg-12" v-if="subnavData.dropDownType == 'links'">
          <div class="sub-menu-grid" role="menu">
            <div class="sub-menu-column" v-for="(column, colIndex) in subnavData.dropDownContent" :key="'column-' + colIndex">
              <ul class="sub-menu-list">
                <li v-for="(item, itemIndex) in column" :key="'item-' + itemIndex">
                  <a :href="item.url" v-html="item.title"></a>
                  <ul v-if="item.childLinks">
                    <li v-for="(child, childIndex) in item.childLinks" :key="'child-' + childIndex">
                      <a :href="child.url" v-html="child.title"></a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </nav>

      </div>
    </div>
  </div>

</template>