<script>
  import _ from 'lodash';
  import buttonClipboardCopy from './button-clipboard-copy';

  export default {
    components: {
      'button-clipboard-copy': buttonClipboardCopy
    },
    props: {
      office: {type: Object},
    },
    computed: {
      addressLine1: function() {
        return this.office.address;
      },
      addressLine2: function() {
        return [[this.office.city, this.office.postal_area].join(', '), this.office.postal_code].join(' ');
      },
      officeImageUrl: function() {
        if (this.office.hero_image_url) {
          return this.office.hero_image_url;
        }
        return "/images/demo/offices-sample.jpg";
      },
      managingPartnerTitle: function() {
        if (this.office.manager_title) {
          return this.office.manager_title;
        }
        return "Office Managing Principal";
      },
      hasPrincipals: function() {
        return (this.principals && this.principals.size > 0);
      },
      principals: function() {
        if (this.office.principals) {
          return _.slice(this.office.principals, 0, 2);
        }
      },
      officeUrl: function() {
        if (this.office.url) {
          return this.office.url;
        }
        return "#";
      },
      copyURL: function() {
        return this.addressLine1 +' '+ this.addressLine2;
      }
    },
    methods: {
      copy: function (event) {
        //this.office.address
        navigator.clipboard.writeText(this.addressLine1 +' '+ this.addressLine2).then(function() {
          // @TD Clipboard (Tooltip)
        }, function() {

        });
      },
      hasTitle: function(person) {
        return person.title;
      },
      hasPhone: function(person) {
        return person.phone;
      },
      hasEmail: function(person) {
        return person.email;
      },
      openOfficeUrl: function(officeUrl, event) {
        if(event.target.classList.contains('telephone-number') ||
           event.target.classList.contains('icon-link') ||
           event.target.classList.contains('icon-clipboard') ||
           event.target.classList.contains('btn-clipboard') ||
           event.target.classList.contains('get-directions') || event.target.tagName == 'A' || event.target.tagName == 'BUTTON') {
          return;
        }

        window.open(officeUrl, '_self');
      }
    }
  }

</script>

<template>
  <div class="col-sm-16 col-md-16 col-lg-8 office-landing-card">
    <div class="card row" @click=openOfficeUrl(officeUrl,$event)>
      <div class="office-landing-info col-sm-16 col-md-8 col-lg-4">
        <h2 class="office-landing-card-header"><a :href="officeUrl">{{office.name}}</a></h2>
        <p v-if="this.addressLine1">
          <span class="office-landing-address" ref="officeClipboard">
            <span>{{addressLine1}}</span>
            <span>{{addressLine2}}<button-clipboard-copy :address="copyURL"></button-clipboard-copy></span>
          </span>

          <a :href="`tel:${this.office.telephone_number}`" class='telephone-number'>{{this.office.telephone_number}}</a>
        </p>

        <div class="office-landing-card-meta">
          <ul>
            <li class="arrow-link small"><a :href="officeUrl">View office<span class="icon-arrow-forward-small"></span></a></li>
            <li v-if="office.map_url"><a class='get-directions' :href="office.map_url" target='_blank'><span class="label">Get directions</span><span class="icon-map"></span></a></li>
          </ul>
        </div>
      </div>
        <div class="office-landing-figure col-md-8 col-lg-4">
        <figure>
          <img :src="officeImageUrl" :alt="office.name + ' Office Detail'" loading="lazy">
        </figure>
      </div>
    </div>
  </div>
</template>