<script>

  import debounce from 'lodash.debounce';
  import { state } from './state'

  export default {
    data: ()=>({
      showGrid: false,
      width: null,
      breakpoint: null,
      bp: state
    }),
    mounted() {
      /*
      this.width = window.innerWidth;
      window.onresize = debounce(() => {
        this.onResize();
      }, 50)
      */
      

    },
    methods: {
      //onResize(event) {
        //this.width = window.innerWidth;
      //}
    },
    computed: {
      colClass: function() {

        if(this.bp.size === 'sm') {
          return 'col-sm-4';
        } else if(this.bp.size === 'md') {
          return 'col-md-2';
        } else if(this.bp.size === 'lg') {
          return 'col-1';
        } else {
          return 'col-1';
        }
      }
    }        
  }

</script>

<template>
  <div class="dev-panel">
    <div class="container" v-if="showGrid">
      <div class="row">
        <div v-for="index in 4" v-bind:key="index" class="col" :class="colClass" v-if="bp.size === 'sm'"></div>
        <div v-for="index in 8" v-bind:key="index" class="col" :class="colClass" v-if="bp.size === 'md'"></div>
        <div v-for="index in 16" v-bind:key="index" class="col" :class="colClass" v-if="bp.size === 'lg' || bp.size === 'xl'" ></div>
      </div>
    </div>
    <div class="control-panel">
      <div class="grid-control">
        <input type="checkbox" v-model="showGrid" id="showGrid" />
        <label for="showGrid">Show Grid</label>
      </div>
      <ul>
        <!--<li><strong>Width:</strong> {{width}}</li>-->
        <li><strong>Breakpoint:</strong> {{bp.size.toUpperCase()}}</li>
      </ul>
    </div>
  </div>
</template>