<script>
  export default {
    data: ()=>({
      showModal: false,
      url: '',
    }),
    props: {
      // url: String,
    },
    mounted: function() {
      document.addEventListener('click', (event) => {
        this.url = event.target.getAttribute('href');
        if(this.isTargetBlank(event) && this.isExternalURL()) {
          event.preventDefault();
          this.toggleModal();
        }
      });
    },
    computed : {
      trimUrl: function() {
        return this.url.trim();
      },
    },
    methods: {
      toggleModal() {
        this.showModal = !this.showModal;
        if(this.showModal) {
          document.body.classList.add('modal-open');
        }else{
          document.body.classList.remove('modal-open');
        }
      },
      handleLeavingSite() {
       window.open(this.trimUrl, '_blank');
       this.toggleModal();
      },
      isTargetBlank(event) {
        return event.target.getAttribute('target') ==='_blank' || event.target.getAttribute('target') === 'blank'
      },
      isExternalURL() {
        const fishDomains = [
          'fish-richardson.lndo.site',
          'www.fish-richardson.qa.3whst.com',
          'www.fr.com'
        ]
        const currentHost = new URL(window.location.origin).host;
        const targetHost = new URL(this.trimUrl).host;
        if (fishDomains.includes(targetHost)) {
          return false;
        } else {
          return /^(http|https):\/\//.test(this.trimUrl) && currentHost !== targetHost;
        }
      }
    }
  }
</script>

<template>
  <div class="leaving-site-modal">
    <a class="site-link" @click="toggleModal"></a>
    <modal :showModal="showModal" :toggleModal="toggleModal">
      <h4 slot="body" v-html="'You are about to leave the Fish & Richardson website.'"></h4>
      <button slot="button-ok" type="button" class="agree-button" @click="handleLeavingSite">
        Continue to the other site
      </button>
      <button slot="button-cancel" type="button" class="cancel-button" @click="toggleModal">
        Remain on fr.com
      </button>
    </modal>
    </div>
</template>
