<script>
  import { state } from './state';
  export default {
    props: {
      active: {
        type: Boolean,
        default: false
      },
      offices: {
        type: Array,
        default: ()=>[]
      }
    },
    data: function() {
      return {
        mobileActive: false,
        state: state
      };
    },
    methods: {
      clickHandler: function() {
        this.mobileActive = !this.mobileActive;
        if(this.mobileActive) {
          document.body.classList.add('office-dropdown-expanded');
        } else {
          document.body.classList.remove('office-dropdown-expanded');
        }
      }
    }
  }
</script>

<template>
  <div class="page-nav-element" :class="{'mobile-active': mobileActive}">
    <button
      class="page-nav-trigger strong"
      @click="clickHandler">
      Quick link to office
      <span class="icon-carrot-down"></span>
    </button>
    <nav class="page-nav-list" :class="{active: mobileActive}">
      <ul>
        <li v-for="office in offices" :key="office.id">
          <a :href="office.url">
            {{office.name}}
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>
