import p5 from "p5";

const headerSketch = function (p) {
  let params = {
    treeCount: 10,

    scl: 20,
    maxDistVal: 1.5,

    randOffsetX: 0,
    randOffsetY: 0,
    randomDelete: .5,
    divideCount: 2,

    growingTime: 10,
    lineLen: 4,
    endings: .2,

    colorMode: 0,

    // do i need this
    showLine: true,
    showGrid: true,

    highlight: true,
    showCross: true,
    showPoints: true,
    showSegments: true,
    showEndings: true,
    showDoubleLine: true
  }

  let _trees
  let _leaves;
  let _max_dist

  let _quadtree

  let _clrs = [
    hexToRgb("#001C34"),
    hexToRgb("#E9F0F2"),
    hexToRgb("#D8F62F"), //changed this value 10.118.22
    hexToRgb("#00A8B6"),
  ]

  function setupTime() {
    let date = new Date()
    let minutes = date.getMinutes()
    if (minutes % 2 == 0) {
      _clrs[1] = hexToRgb('#00A8B5')
      _clrs[2] = hexToRgb('#FFFFFF')
      _clrs[3] = hexToRgb('#1A3EEA')
    } else {
      _clrs[1] = hexToRgb('#00A8B5')
      _clrs[2] = hexToRgb('#FFFFFF')
      _clrs[3] = hexToRgb('#1A3EEA')
    }


    setInterval(() => {
      setupGraphics()
    }, 1000 * 15)

    setInterval(() => {
      date = new Date()
      minutes = date.getMinutes()
      if (minutes % 2 == 0) {
        _clrs[1] = hexToRgb('#00A8B5')
        _clrs[2] = hexToRgb('#FFFFFF')
        _clrs[3] = hexToRgb('#1A3EEA')
      } else {
      _clrs[1] = hexToRgb('#00A8B5')
      _clrs[2] = hexToRgb('#FFFFFF')
      _clrs[3] = hexToRgb('#1A3EEA')
      }
    }, 1000)
  }


  setupTime()

  let _canvas

  let _removeTrees = false
  let _redraw = true
  let _img
  let _w, _h

  const _speed = 0.01

  //————————————————————————————————————————————— preload
  p.preload = function () {
    _img = p.loadImage('IP.jpg')
  }

  //————————————————————————————————————————————— setup
  p.setup = function () {
    getwidthAndheight()
    _canvas = p.createCanvas(_w, _h, p.P2D);
    _canvas.parent("#myCanvas")
    p.pixelDensity(2)

    // init quadtree
    _quadtree = new QuadTree(Infinity, 30, new Rect(0, 0, p.width, p.height));

    setupGraphics()

    p.rectMode(p.CENTER)
  }

  function getwidthAndheight() {
    // resize and center image
    var myDiv = document.getElementById('myCanvas');
    _w = myDiv.offsetWidth
    _h = myDiv.offsetHeight

    params.scl = p.floor(p.map(_w, 0, 1500, 10, 25))
  }

  p.windowResized = function () {
    getwidthAndheight()
    p.resizeCanvas(_w, _h, p.P2D)
    _quadtree = new QuadTree(Infinity, 30, new Rect(0, 0, p.width, p.height));
    setupGraphics()
  }

  //————————————————————————————————————————————— draw
  p.draw = function () {
    // p.background(..._clrs[0]);
    p.clear()

    // show leaves
    p.noStroke();
    p.fill(..._clrs[1], 125);
    for (var i = 0; i < _leaves.length; i++) {
      const leaf = _leaves[i]
      if (params.showGrid) leaf.show();
    }

    // show graphics
    p.noFill()
    for (let idx in _trees) {
      const tree = _trees[idx]
      p.stroke(..._clrs[tree.color])
      tree.show();
    }

    if (_removeTrees) {
      let count = 0
      for (let idx in _trees) {
        const tree = _trees[idx]
        if (!tree.empty) {
          tree.removePath()
          tree.finished = true
        } else {
          count++
        }
      }

      if (count == _trees.length) {
        _redraw = true
        setupGraphics()
      }
    }
  }

  //————————————————————————————————————————————— ENDDRAW
  //————————————————————————————————————————————— ENDDRAW
  //————————————————————————————————————————————— ENDDRAW
  //————————————————————————————————————————————— ENDDRAW
  //————————————————————————————————————————————— ENDDRAW
  //————————————————————————————————————————————— ENDDRAW
  //————————————————————————————————————————————— ENDDRAW

  //————————————————————————————————————————————— setupgraphics
  function setupGraphics() {
    p.noiseSeed(p.random(1000))

    var _finished; // Added By Igicom
    var _leafCount; // Added By Igicom

    // recalculate values
    if (_redraw) {
      _redraw = false
      _removeTrees = false
      _max_dist = Math.sqrt(Math.pow(params.scl, 2) * 2) * params.maxDistVal
      // reset values
      _finished = false
      _leafCount = 0
      _trees = []
      _leaves = []

      _img.resize(p.width, p.height)
      _img.loadPixels()
      const step = params.scl
      let idx = 0
      for (let x = 0; x < p.width; x += step) {
        for (let y = 0; y < p.height; y += step) {
          const pixelIdx = (x + y * p.width) * 4 // rgba that is why you 
          const bri = _img.pixels[pixelIdx]
          if (bri == 255 && p.random(1) < params.randomDelete) {
            const xoff = p.random(1) > 0.5 ? p.random(-params.randOffsetX, params.randOffsetX) : 0
            const yoff = xoff == 0 && p.random(1) > 0.5 ? p.random(-params.randOffsetY, params.randOffsetY) : 0
            const nx = x + xoff
            const ny = y + yoff
            const pos = p.createVector(nx, ny)
            _leaves.push(new Leaf(pos, idx));
            idx++
          }
        }
      }

      // create trees
      for (let i = 0; i < params.treeCount; i++) {
        const randomLeaf = p.random(_leaves)
        const pos = randomLeaf.pos
        const tree = new Tree(pos);
        _trees.push(tree)
      }

      // quadtree clear
      _quadtree.clear();
      for (const leaf of _leaves) {
        _quadtree.addItem(leaf.pos.x, leaf.pos.y, leaf);
      }
    } else {
      _removeTrees = true
    }

    // find every branch before the beginning of it all
    let countFinishedTrees = 0;
    while (countFinishedTrees < _trees.length) {
      countFinishedTrees = 0
      // let total = 0
      for (let idx in _trees) {
        const tree = _trees[idx]
        if (!tree.finished) {
          tree.grow();
        } else if (!tree.branchSet) {
          tree.findBranch()
        }
        if (tree.finished) {
          countFinishedTrees++
        }
      }
    }
  }

  function hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? [
      parseInt(result[1], 16),
      parseInt(result[2], 16),
      parseInt(result[3], 16)
    ] : null;
  }

  //————————————————————————————————————————————— Tree
  //————————————————————————————————————————————— Tree
  //————————————————————————————————————————————— Tree
  function Tree(pos) {
    this.branches = [];
    this.specialBranches = []
    this.empty = false

    var pos = pos ? pos : createVector(p.width / 2, p.height / 2);
    var root = new Branch(null, pos);

    this.color = p.floor(p.random(1, 4))
    this.branches.push(root);
    this.transitioned = false
    this.transitionCount = 0

    this.branchesColors = []
    this.branchesWeight = []

    this.findBranch = function () {
      this.branchSet = true
      let idx = 0
      for (let n = 0; n < this.branches.length; n++) {
        let parent = this.branches[n]
        if (!parent.inSpecial) {
          this.specialBranches.push([])
          for (let i = 0; i < parent.children.length; i++) {
            let child = parent.children[i]
            while (child != null) {
              this.specialBranches[idx].push(child)
              child.inSpecial = true
              child = child.child
            }
          }
          if (this.specialBranches[idx].length == 0) this.specialBranches.splice(idx, 1)
          else idx++
        }
      }

      // add Each color for a branch
      for (let i = 0; i < this.specialBranches.length; i++) {
        this.branchesColors.push(p.floor(p.random(1, 4)))
        this.branchesWeight.push(p.random(1) < 0.2)
      }
    }

    this.removePath = function () {
      if (this.branches.length > 0) {
        for (let n = 0; n < 10; n++) {
          const branch = this.branches[this.branches.length - 1]
          // branch.pop()
          for (let i = 0; i < this.specialBranches.length; i++) {
            for (let j = 0; j < this.specialBranches[i].length; j++) {
              if (this.specialBranches[i][j] == branch) {
                this.specialBranches[i].splice(j, 1)
                break
              }
            }
          }
          this.branches.pop()
        }
      } else {
        this.empty = true
      }
    }

    this.grow = function () {
      this.finished = true
      for (var j = this.branches.length - 1; j >= 0; j--) {
        const branch = this.branches[j];
        let closestBranch = null;

        if (!branch.full) {
          let chosenLeaf = null
          let record = _max_dist;

          for (const other of _quadtree.getItemsInRadius(branch.pos.x, branch.pos.y, _max_dist, 100)) {
            const leaf = other;
            const d = p5.Vector.dist(leaf.pos, branch.pos);
            if (d < record && !leaf.reached) {
              closestBranch = branch
              chosenLeaf = leaf
              record = d;
            }
          }

          if (closestBranch != null) {
            const nextBranch = branch.next(chosenLeaf)
            this.branches.push(nextBranch);
            nextBranch.isGrowing = true
            chosenLeaf.reached = true
            this.finished = false
          } else {
            branch.full = true
          }
        }
      }
    }

    this.show = function () {
      p.strokeWeight(1)
      for (var i = 0; i < this.branches.length; i++) {
        const branch = this.branches[i]
        if (!branch.inSpecial) branch.show();
      }

      for (let n = 0; n < this.specialBranches.length; n++) {
        const col = this.branchesColors[n]
        const bri = p.map(n, 0, this.specialBranches[n].length - 1, 125, 255)
        if (params.highlight) p.strokeWeight(this.branchesWeight[n] ? 2 : 1)
        if (params.colorMode == 0) p.stroke(_clrs[col])
        if (params.colorMode == 1) p.stroke(..._clrs[this.color], bri)
        const currBranch = this.specialBranches[n]
        for (var i = 0; i < currBranch.length; i++) {
          const branch = currBranch[i]
          branch.show();
        }
      }
    }
  }


  //————————————————————————————————————————————— Leaf
  //————————————————————————————————————————————— Leaf
  //————————————————————————————————————————————— Leaf
  function Leaf(pos, idx) {
    this.pos = pos ? pos : createVector(p.random(p.width), p.random(p.height));
    this.reached = false;
    this.idx = idx

    this.show = function () {
      p.ellipse(this.pos.x, this.pos.y, 2, 2);
    };
  }

  //————————————————————————————————————————————— Branch
  //————————————————————————————————————————————— Branch
  //————————————————————————————————————————————— Branch
  function Branch(parent, pos) {
    this.pos = pos;
    this.origPos = this.pos.copy()
    this.parent = parent;
    this.child = null
    this.children = []
    this.count = 0;
    this.len = params.scl;
    this.isGrowing = false
    this.growingCount = 0
    this.transitioned = false
    this.endingSpeed = p.random(0.5, 1)

    // special case to draw special rects
    if (p.random(1) < params.endings) this.special = p.floor(p.random(2, p.random(2, 5)))
    else this.special = 1

    this.animate = p.random(1) > 0.5

    this.speed = p.random(_speed / 2., _speed * 2)

    this.look = p.floor(p.random(5))
    this.fc = Math.random(1)

    this.next = function (leaf) {
      var nextBranch = new Branch(this, leaf.pos);
      this.child = nextBranch
      this.children.push(this.child)
      return nextBranch;
    };

    this.show = function () {
      // the ending stuff
      if (this.child == null && params.showEndings && this.special > 1) {
        for (let i = 1; i <= this.special; i++) {
          let percent = 1
          let transitionPercent = 1
          if (!this.transitioned) {
            this.growingCount += this.endingSpeed
            if (this.growingCount > (params.growingTime * 20)) {
              this.transitioned = true
            }
            transitionPercent = (this.growingCount) / (params.growingTime * 20)
          }

          if (this.animate) {
            percent = p.map(p.sin(((this.fc * 0.25 + i * .1) % 1) * p.TWO_PI), -1, 1, 0, 1) * transitionPercent
          } else {
            percent = p.map(p.sin(((this.fc * 0.25 / 4 + i * .1) % 1) * p.TWO_PI), -1, 1, 0, 1) * transitionPercent
          }

          p.ellipse(this.pos.x, this.pos.y, this.len * i * percent, this.len * i * percent);
        }
      }

      // the line stuff
      if (this.parent == null || !this.parent.isGrowing) {
        if (this.isGrowing) {
          this.growingCount++
          if (this.growingCount % params.growingTime == 0) {
            this.isGrowing = false
          }
          const lerpPos = p5.Vector.lerp(this.parent.pos, this.pos, this.growingCount / params.growingTime)
          if (this.child == null && params.showEndings && this.special > 1) {} else {
            p.line(lerpPos.x, lerpPos.y, this.parent.pos.x, this.parent.pos.y);
          }
        } else {
          // if it is has a parent so it is not the first node
          if (parent != null) {
            // if this is the last node where the leaf should be created
            if (this.child == null && params.showEndings && this.special > 1) {} else {
              if (this.look == 0 && params.showSegments) {
                // if the calculation is done, do this
                if (this.calculatedSegments) this.showLineSegments()
                else {
                  this.calculateLineSegments()
                }
              } else if (this.look == 1 && params.showPoints) {
                this.showPoints()
              } else if (this.look == 2 && params.showCross) {
                this.showCross()
              } else if (this.look == 3 && params.showDoubleLine) {
                this.showSaw()
              } else if (params.showLine) {
                p.line(this.pos.x, this.pos.y, this.parent.pos.x, this.parent.pos.y);
              }
            }
          }
        }
        this.fc += this.speed
      }
    };

    this.showPoints = function () {
      for (let i = 0; i < params.divideCount; i++) {
        const lerpPos = p5.Vector.lerp(this.pos, this.parent.pos, (i / params.divideCount / 1.0 + this.fc) % 1.0)
        p.ellipse(lerpPos.x, lerpPos.y, 1, 1);
      }
    }

    this.showCross = function () {
      p.push()
      p.translate(this.pos.x, this.pos.y)
      if (this.animate) p.rotate(p.PI / 2 + this.fc)
      else p.rotate(p.PI / 4 + this.fc)
      p.line(-params.scl / params.lineLen, 0, params.scl / params.lineLen, 0);
      p.line(0, -params.scl / params.lineLen, 0, params.scl / params.lineLen);
      p.pop()
    }

    this.showLineSegments = function () {
      for (let i = 0; i < params.divideCount; i++) {
        const segment = this.segments[i]
        p.push()
        p.translate(segment.x, segment.y)
        p.rotate(segment.angle + p.PI / 2)
        p.line(-params.scl / params.lineLen, 0, params.scl / params.lineLen, 0);
        p.pop()
      }
    }

    this.showSaw = function () {
      const v1 = p5.Vector.sub(this.pos.copy(), this.parent.pos.copy())
      v1.rotate(p.HALF_PI)
      v1.setMag(params.scl / 3)
      const v2 = this.pos.copy().add(v1)
      const v3 = this.parent.pos.copy().add(v1)

      p.line(this.pos.x, this.pos.y, this.parent.pos.x, this.parent.pos.y);
      p.line(v2.x, v2.y, v3.x, v3.y);
    }

    // calculate only ones to increase performance
    this.calculateLineSegments = function () {
      this.calculatedSegments = true
      this.segments = []
      for (let i = 0; i < params.divideCount; i++) {
        const lerpPos = p5.Vector.lerp(this.pos, this.parent.pos, (i / params.divideCount / 1.0))
        const v1 = p5.Vector.sub(this.pos.copy(), this.parent.pos.copy())
        const v2 = p.createVector(1, 0)
        let angle = v2.angleBetween(v1)
        this.segments.push({
          x: lerpPos.x,
          y: lerpPos.y,
          angle: angle
        })
      }
    }
  }

  //————————————————————————————————————————————— QuadTree
  //————————————————————————————————————————————— QuadTree
  //————————————————————————————————————————————— QuadTree
  /*
   * Private class.
   * A container object used to hold user-defined data within a `QuadTreeBin`.
   * Stores it's position within the QuadTree domain.
   */
  class QuadTreeItem {
    constructor(x, y, data) {
      this.x = x;
      this.y = y;
      this.data = data;
    }

  }


  /*
   * Private class.
   * A spatial region of a QuadTree containing 0 or more `QuadTreeItem` instances and 0 or more other `QuadTreeBin` instances.
   */
  class QuadTreeBin {

    /*
     * @param maxDepth The maximum number of permitted subdivisions.
     * @param maxItemsPerBin The maximum number of items in a single bin before it is subdivided.
     * @param extent A `Rect` instance specifying the bounds of this `QuadTreeBin` instance within the QuadTree domain.
     * @param depth For internal use only.
     */
    constructor(maxDepth, maxItemsPerBin, extent, depth = 0) {
      this.rect = extent.copy();
      this.bins = null;
      this.maxDepth = maxDepth;
      this.maxItemsPerBin = maxItemsPerBin;
      this.items = [];
      this.depth = depth;
    }

    /*
     * Check if a point is within the extent of a `QuadTreeBin` instance.
     * Returns true if so, false otherwise.
     * @param range Used to check if a point is within a radius of the extent border.
     */
    checkWithinExtent(x, y, range = 0) {
      return x >= this.rect.x - range && x < this.rect.x + this.rect.width + range &&
        y >= this.rect.y - range && y < this.rect.y + this.rect.height + range;
    }

    /*
     * Adds an item to the `QuadTreeBin`.
     * @param item An instance of `QuadTreeItem`.
     */
    addItem(item) {
      if (this.bins === null) {
        this.items.push(item);
        if (this.depth < this.maxDepth && this.items !== null && this.items.length > this.maxItemsPerBin)
          this.subDivide();
      } else {
        const binIndex = this._getBinIndex(item.x, item.y);
        if (binIndex != -1)
          this.bins[binIndex].addItem(item);
      }
    }

    /*
     * Returns a list of items from the bin within the specified radius of the coordinates provided.
     */
    getItemsInRadius(x, y, radius, maxItems) {
      const radiusSqrd = radius ** 2;
      let items = [];

      if (this.bins) {
        for (let b of this.bins)
          if (b.checkWithinExtent(x, y, radius))
            items.push(...b.getItemsInRadius(x, y, radius, maxItems));
      } else {
        for (let item of this.items) {
          const distSqrd = (item.x - x) ** 2 + (item.y - y) ** 2;
          if (distSqrd <= radiusSqrd)
            items.push({
              distSqrd: distSqrd,
              data: item.data
            });
        }
      }

      return items;
    }

    /*
     * Split a `QuadTreeBin` into 4 smaller `QuadTreeBin`s.
     * Removes all `QuadTreeItem`s from the bin and adds them to the appropriate child bins.
     */
    subDivide() {
      if (this.bins !== null) return;
      this.bins = [];
      let w = this.rect.width * 0.5,
        h = this.rect.height * 0.5;
      for (let i = 0; i < 4; ++i)
        this.bins.push(new QuadTreeBin(this.maxDepth, this.maxItemsPerBin, new Rect(this.rect.x + i % 2 * w, this.rect.y + Math.floor(i * 0.5) * h, w, h), this.depth + 1));

      for (let item of this.items) {
        const binIndex = this._getBinIndex(item.x, item.y);
        if (binIndex != -1)
          this.bins[binIndex].addItem(item);
      }

      this.items = null;
    }

    /*
     * Renders the borders of the `QuadTreeBin`s within this `QuadTreeBin`.
     * For debugging purposes.
     */
    debugRender(renderingContext) {
      noFill();
      stroke('#aaa');
      strokeWeight(1);
      rect(this.rect.x, this.rect.y, this.rect.width, this.rect.height);
      if (this.bins)
        for (let b of this.bins)
          b.debugRender(renderingContext);
    }

    /*
     * Private.
     */
    _getBinIndex(x, y, range = 0) {
      if (!this.checkWithinExtent(x, y)) return -1;
      let w = this.rect.width * 0.5,
        h = this.rect.height * 0.5;
      let xx = Math.floor((x - this.rect.x) / w);
      let yy = Math.floor((y - this.rect.y) / h);
      return xx + yy * 2;
    }

  }


  /*
   * A public class representing a QuadTree structure.
   */
  class QuadTree {

    /*
     * @param maxDepth The maximum number of permitted subdivisions.
     * @param maxItemsPerBin The maximum number of items in a single bin before it is subdivided.
     * @param extent A `Rect` instance specifying the bounds of this `QuadTreeBin` instance within the QuadTree domain.
     */
    constructor(maxDepth, maxItemsPerBin, extent) {
      this.extent = extent.copy();
      this.maxDepth = maxDepth;
      this.maxItemsPerBin = maxItemsPerBin;
      this.clear();
    }

    /*
     * Remove all `QuadTreeItem`s and `QuadTreeBin`s from the QuadTree leaving it completely empty.
     */
    clear() {
      this.rootBin = new QuadTreeBin(this.maxDepth, this.maxItemsPerBin, new Rect(0, 0, this.extent.width, this.extent.height));
    }

    /*
     * Add an item at a specified position in the `QuadTree`.
     * @param x The x coordinate of the item.
     * @param y The y coordinate of the item.
     * @param item The user-defined data structure to store in the `QuadTree`.
     */
    addItem(x, y, item) {
      this.rootBin.addItem(new QuadTreeItem(x, y, item));
    }

    /*
     * Returns a list of items within the specified radius of the specified coordinates.
     */
    getItemsInRadius(x, y, radius, maxItems) {
      if (maxItems === undefined) {
        return this.rootBin.getItemsInRadius(x, y, radius);
      } else {
        return this.rootBin.getItemsInRadius(x, y, radius)
          .sort((a, b) => a.distSqrd - b.distSqrd)
          .slice(0, maxItems)
          .map(v => v.data);
      }
    }

    /*
     * Renders the borders of the `QuadTreeBin`s within this `QuadTree`.
     * For debugging purposes.
     */
    debugRender(renderingContext) {
      this.rootBin.debugRender(renderingContext);
    }

  }

  /*
   * A rectangle with `x` and `y` coordinates specifying the top-left corner and a `p.width` and `p.height`
   */
  class Rect {

    // By default, positioned at [0, 0] with a p.width and p.height of 1
    constructor(x = 0, y = 0, width = 1, height = 1) {
      this.x = x;
      this.y = y;
      this.width = width;
      this.height = height;
    }

    /*
     * Return a new rectangle instance with the same values
     */
    copy() {
      return new Rect(this.x, this.y, this.width, this.height);
    }
  }
}

// init header sketch
// let myHeader = new p5(headerSketch)

export default headerSketch;
