<script>

  import tab from './tab';

  export default {

    components: {
      'tab': tab,
    },

    props: {
      title: {type: String, default: 'Tabbed Content'},
      backgroundColor: { type: String, default: "white" },
      people: { type: Boolean, default: false },
      tabs: {
        type: Array,
        default: function() {
          return [];
        }
      }
    },

    mounted() {
      this.panels = this.$children;
      this.panels.forEach((panel, index) => {
        panel.isActive = index === this.selectedTab;
      });
    },

    watch: {
      selectedTab: function (val) {
        this.panels.forEach((panel, index) => {
          panel.isActive = index === this.selectedTab;
        });
      }
    },

    data: function() {
      return {
        tabData: this.tabs,
        selectedTab: 0,
        panels: [],
        isScrolled: false,
      };
    },

    methods: {
      handleScroll: function(e) {
        let sl = this.$refs.tabsScroll.scrollLeft;
        this.isScrolled = (sl > 10) ? true : false;
      }
    }
  }

</script>

<template>
  <section class="module tabs-module" :class="[backgroundColor, people ? 'people' : 'content']">

    <div class="container">
      <div class="tabs">

        <div class="tabs-header" :class="{scrolled : isScrolled}">
          <div class="tabs-scroll" @scroll="handleScroll" ref="tabsScroll">
            <div class="tabs-list" role="tablist" :aria-label="title">
              <template v-for="(tab, index) in tabData">
                <button
                  role="tab"
                  :class="{active : (selectedTab == index) ? true : false}"
                  :disabled="(selectedTab == index) ? true : false"
                  :aria-selected="(selectedTab == index) ? true : false"
                  :aria-controls="`fr-tab-${index}`"
                  :id="`fr-tab-button-${index}`"
                  :data-text="tab.label"
                  @click="selectedTab = index"
                  :key="index + '-tab'"
                >
                  <span class="outer"><span class="inner">{{tab.title}}</span></span>
                </button>
              </template>
            </div>
          </div>
        </div>

        <div class="tabs-body">
          
          <slot name="tab-content" v-if="people"></slot>
  
          <template v-if="!people">
            <tab
              v-for="(tab, index) in tabData"
              :key="index"
              :index="index"
              :tab-content="tab"
              :active="selectedTab == index"
            />
          </template>
        
          
        </div>
      </div>
    </div>
  </section>

</template>
