<script>

  // type: 'post', 'stat', 'recognition', 'testimonial', 'service', 'bio'
  // wide: true = 2 slides visible | false = 3 slides visible
  // sideTitle: true = Title displays in place of first slide (for desktop only)
  // scrollbar: true = Show scrollbar (homepage only place this will be false)
  // narrowPage: true = configure for narrow pages (this will only need to be set true on the bio page)
  // featured: true = different title treatment + NO Scrollbars (homepage only)

  import swiperSlide from './carousel-components/swiper-slide';
  import { state } from './state';
  import Swiper, { Navigation, Scrollbar, Keyboard, A11y } from 'swiper';
  Swiper.use([Navigation, Scrollbar, Keyboard, A11y]);

  export default {
    components: {
      'swiper-slide': swiperSlide,
    },
    data: ()=>({
      hideTools: false,
      isActive: true // For Use with Carousel in Tabs Only
    }),
    props: {
      title: {type: String}, // Display Title
      description: {type: String}, // Display Description
      type: {type: String, default: 'post'}, // Determines Card Type
      wide: {type: Boolean, default: false}, // Displays 2 Across
      narrowColumn: {type: Boolean, default: false}, // Slider is in narrow column, Bio Only, Impacts # of cards / Mutually exclusive with 'wide'
      sideTitle: {type: Boolean, default: true}, // Placement of Title (Impacts #s of cards Across)
      scroll: {type: Boolean, default: true},
      featured: {type: Boolean, default: false}, // Featured Look and Feel (Homepage Only)
      readMore: {type: Object}, // Readmore Link info
      backgroundColor: { type: String, default: "white" },
      isTabbed: {type: Boolean, default: false}, // Is Carousel In a tabbed interface
      isLanding: {type: Boolean, default: false},
      isOtherPage: {type: Boolean, default: false},
    },
    mounted() {
      const el = this.$refs.swiper;
      const self = this;


      const breakpointsDefault = {
          // sm
          0: {
            slidesPerView: (this.type == 'bio') ? 'auto' : 1,
            spaceBetween: (this.type == 'bio') ? 80 : 24,
            scrollbar: this.scrollSettingsSM,
            navigation: {
              enabled: false
            }
          },
          // md
          640: {
            slidesPerView: (this.type == 'bio') ? 'auto' : this.slidesTablet,
            spaceBetween: (this.type == 'bio') ? 80 : 32,
            scrollbar: this.scrollSettingsSM,
            navigation: {
              enabled: false
            }
          },
          // lg
          1024: {
            slidesPerView: (this.type == 'bio') ? 'auto' : this.slidesDesktop,
            spaceBetween: 80,
            scrollbar: this.scrollSettingsLG
          },
          // xl
          1360: {
            slidesPerView: (this.type == 'bio') ? 'auto' : this.slidesDesktop,
            spaceBetween: (this.type == 'bio') ? 80 : 32,
            scrollbar: this.scrollSettingsLG
          }
        };

      const swiper = new Swiper(el, {
        
        watchSlidesProgress: true,
        keyboard: {
          enabled: true,
          onlyInViewport: true,
        },
        a11y: {
          enabled: true,
        },
        // resizeObserver: false,
        //cssMode: true
        scrollbar: this.scrollSettings,
        navigation: {
          nextEl: this.$refs.swiperNext,
          prevEl: this.$refs.swiperPrev,
        },
        breakpoints: breakpointsDefault,
        on: {
          init: function() {
            self.hideTools = (this.allowSlideNext) ? false : true;
          },
          resize: function() {
            self.hideTools = (this.allowSlideNext) ? false : true;
          }
        }
      });
    },

    computed: {
      headerClass() {
        return (this.sideTitle) ? 'col-sm-16 col-lg-4' : 'col-sm-16';
      },
      titleClass() {
        return (this.featured) ? 'h3' : 'h5 strong';
      },
      gridClass() {
        if(this.wide && this.sideTitle) {
          return 'col-sm-16 col-md-12 col-lg-12 col-xl-12'
        }
        else if(this.sideTitle) {
          return 'col-sm-16 col-lg-12 col-xl-12'
        }
        else {
          return 'col-sm-16';
        }
      },
      showReadMore: function() {
        return (this.readMore && this.readMore.url);
      },
      readMoreLabel: function() {
        if (this.readMore && this.readMore.label) {
          return this.readMore.label;
        }
        return "See All";
      },
      slidesTablet() {
        if(this.wide) {
          return 1;
        }
        else {
          return 2;
        }
      },
      slidesDesktop() {
        if(this.wide) {
          return 2;
        }else if(!this.isLanding && this.isOtherPage){
          return 3;
        }
        else if(!this.sideTitle && !this.narrowColumn) {
          return 4;
        }
        else {
          return 3;
        }
      },
      scrollSettings() {
        if(this.scroll) {
          return {
            el: this.$refs.swiperScroll,
            draggable: true,
            enabled: true
          }
        } else {
          return { enabled: false }
        }
      },
      scrollSettingsSM() {
        if(this.scroll) {
          return { enabled: true }
        } else {
          return { enabled: false }
        }
      },
      scrollSettingsLG() {
        if(this.scroll) {
          if(this.featured) {
            return { enabled: false }
          } else {
            return { enabled: true }
          }
        } else {
          return { enabled: false }
        }

      },
    }
  }

</script>

<template>

  <section
    class="module carousel carousel-component"
    :class="[
      type,
      backgroundColor,
      {wide: wide},
      {'narrow-column': narrowColumn},
      {'side-title': sideTitle},
      {'top-title': !sideTitle},
      {'scrollbar': scroll},
      {'featured': featured},
      {'no-buttons' : hideTools }
    ]"
    v-show="isActive && !isTabbed"
  >

    <div class="container">
      <div class="row carousel-row">
        <header :class="headerClass" class="carousel-title">
          <div>
            <h2 :class="titleClass" class="carousel-header" v-html="title"></h2>
            <p class="carousel-description mb-none" v-if="description" v-html="description"></p>
          </div>
          <a :href="readMore.url" class="btn-cta basic read-more" v-if="showReadMore">
            {{readMore.label}}
            <span class="circle-icon" aria-hidden="true">
              <span class="icon-carrot-circle off"></span>
              <span class="icon-carrot-circle-solid on"></span>
            </span>
          </a>
        </header>

        <div :class="gridClass" class="carousel-column">
          <div class="swiper" ref="swiper">
            <div class="swiper-wrapper">
              <slot name="swiper-slides">
              </slot>
              <!-- <swiper-slide v-for="index in 7" :key="index" :type="type" /> -->
            </div>
            <div class="carousel-tools">
              <ul>
                <li><button class="swiper-button-prev" ref="swiperPrev"><span class="icon-arrow-forward-small"></span></button></li>
                <li><button class="swiper-button-next" ref="swiperNext"><span class="icon-arrow-forward-small"></span></button></li>
              </ul>
              <div class="swiper-scrollbar" ref="swiperScroll" v-if="scroll"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

</template>
