<script>
  import _ from 'lodash';

  export default {
    name: "collection-select",
    props: {
      options: null,
      placeholder: {
        default: "placeholder here"
      },
      name: null,
      resetSelectionLabel: null,
      value: null
    },
    data: function() {
      var initialSelection = null;
      if (this.options && this.value) {
        let self = this;
        initialSelection = _.find(this.flattenedOptions(), function(o) {
          return o.id == self.value;
        });
      }
      return {
        selected: initialSelection,
        active_el: 0,
        initialValue: this.value,
      };
    },
    watch: {
      value: function(newVal) {
        this.selected = newVal;
        console.log("Selected changed");
      }
    },
    mounted() {
      this.$root.$on("clear-advanced-search", () => {
        this.selected = null;
        this.initialValue = null;
      });
    },
    computed: {
      isIndustriesOrServices: function() {
        return this.name?.includes("industry") || this.name?.includes("service");
      },
      selectedName: function() {
        if (this.selected) {
          return this.selected.name;
        }
        return null;
      },
      selectedId: function() {
        if (this.selected) {
          return this.selected.id;
        }
        return null;
      },
      displayLabel: function() {
        if (this.name === "language_id") {
          return "All languages spoken"
        }
        return `All ${this.resetSelectionLabel.toLowerCase()}`
      },
      formattedOptions: function() {
        if(!this.isIndustriesOrServices) {
          return [{id:'reset', name: this.displayLabel }, ...this.options]
        }
        let options = [];
        this.options.forEach(element => {
          options.push({id: element.id, name: element.name, isParent: this.isIndustriesOrServices && true})
          if(element.children) {
            element.children.forEach(child => {
              options.push({id: child.id, name: child.name, isChild: true})
            })
          }
        });
        return [{id:'reset', name: this.displayLabel }, ...options]
      },
      currentSelection: function() {
        this.$root.$emit("search-updated")
        return String(this.initialValue) === String(this.selected.id);
      }
    },
    methods: {
       flattenedOptions: function() {
        let options = [];
        this.options.forEach(element => {
          options.push({id: element.id, name: element.name})
          if(element.children) {
            element.children.forEach(child => {
              options.push({id: child.id, name: child.name})
            })
          }
        });
        return options
      },
      update: function(selections) {
        if(selections.id === 'reset') {
          this.selected = null;
          this.initialValue = null;
          this.$root.$emit("search-removed", this.name);
        }else{
          this.$emit('change', selections)
          if(this.selectedId) {
            this.$root.$emit("search-added", this.name);
          }else{
            this.$root.$emit("search-removed", this.name);
          }
        }
      }
    }
  }

</script>

<template>

  <div class="selected">
    <v-select
      v-model="selected"
      :closeOnSelect="true"
      :clearable="true"
      :autocomplete="selectedName"
      :searchable="true"
      :options="formattedOptions"
      :name="name"
      label="name"
      @input="update"
      :placeholder="placeholder"
      :class="{ active : active_el == 1 }"
      class="drop-search-field test">
      <template #open-indicator="{ attributes }">
        <span v-bind="attributes"><span class="icon-carrot-down"></span></span>
      </template>

      <template v-slot:option="option">
        <span v-html="option.name" :class="[(option.isChild ? 'is-child' : ''), (option.isParent ? 'is-parent' : '')]"></span>
      </template>
      <template #selected-option="{ name }">
        <div style="display: flex; align-items: baseline">
          <span v-html="name" :class="currentSelection ? 'highlight-red' : ''"></span>
        </div>
      </template>

    </v-select>
    <input type="hidden" :name="name" :value="selectedId" data-selector='is-query'>
  </div>

</template>