<script>

  import { state } from './state';

  export default {
    data: ()=>({
      state: state,
      showReadMore: false,
      savedWindowPosition: null
    }),
    props: {
      index: {type: Number},
      active: {type: Boolean},
      tabContent: {type: Object}
    },
    computed: {
      hasReadMore() {
        if(this.tabContent.read_more_content) return true;
      },
      readMoreLabel: function() {
        if (this.tabContent.read_more_label) {
          return this.tabContent.read_more_label;
        }
        return "Show more";
      },
      readLessLabel: function() {
        if (this.tabContent.read_less_label) {
          return this.tabContent.read_less_label;
        }
        return "Show less";
      }
    },
    watch: {
      active(val) {
        this.showReadMore = false;
        if(!val) {
          //this.showReadMore = false;
        }
      },
      showReadMore(val) {
        if(val) {
          this.savedWindowPosition = window.scrollY;
        } else {

          // Restore Original Position
          
          if(this.savedWindowPosition) {
            console.log(this.savedWindowPosition, window.scrollY);
            if(window.scrollY > this.savedWindowPosition) {
              window.scrollTo(0, this.savedWindowPosition)
            }
          }
        }
      }
    }
  }

</script>

<template>

  <div
    role="tabpanel"
    :id="`fr-tab-${index}`"
    :aria-labelledby="`fr-tab-button-${index}`"
    class="tabpanel"
    :class="{active : active}"
  >

    <div class="row">
      <div class="col-sm-16 col-lg-8">
        <h2 class="h3 tabs-heading">{{tabContent.callout}}</h2>
      </div>

      <div class="col-sm-16 col-lg-8">
        <div v-html="tabContent.body" class="mce-editor tab-content-body"></div>
      
        <div v-if="hasReadMore">

          <div v-html="tabContent.read_more_content" class="mce-editor tab-content-more" :class="{ active: showReadMore }"></div>

          <button
            class="btn-toggle"
            @click="showReadMore = !showReadMore"
          >
            
            <template v-if="!showReadMore">
              {{readMoreLabel}}
              <span class="circle-icon" aria-hidden="true">
                <span class="icon-plus-circle off"></span>
                <span class="icon-plus-circle-solid on"></span>
              </span>
            </template>
            
            <template v-else>
              {{readLessLabel}}
              <span class="circle-icon" aria-hidden="true">
                <span class="icon-minus-circle off"></span>
                <span class="icon-minus-circle-solid on"></span>
              </span>
            </template>
            
          </button>
        </div>


      </div>

    </div>
  
  </div>

</template>