<script>

  import * as focusTrap from 'focus-trap';

  export default {
    props: {
      showModal: Boolean,
      toggleModal: Function,
    },
    data: ()=>({
      trap: null
    }),
    mounted() {
      // console.log(this.$refs.modal);
    },
    methods: {
      deactiveModalWithEscape(e) {
        if (e.key === 'Escape') {
          this.toggleModal();
        }
      },
    },
    watch: {
      showModal(val) {
        document.addEventListener('keydown', this.deactiveModalWithEscape, false);
        if(val) {
          this.$nextTick(() => {
            this.trap = focusTrap.createFocusTrap(this.$refs.modal);
            this.trap.activate();
          });        
        } else {
          document.removeEventListener('keydown', this.deactiveModalWithEscape, false);
          if(this.trap) {
            this.trap.deactivate();
          }
        }
      }    
    }
  }
</script>

<template>
  <div class="modal">
      <transition name="modal" v-if="showModal" >
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-container" role="modal" aria-live="polite" ref="modal">
              <button type="button" aria-label="Close Modal" class="modal-close-button" @click="toggleModal"><span aria-hidden="true" class="icon-close-sm-circle"></span></button>
              <div class="modal-header-border"></div>
              <div class="inner-wrapper">
                <div class="modal-body">
                  <slot name="body">
                  </slot>
                </div>
                <div class="modal-footer">
                  <slot name="button-ok">
                  </slot>
                  <slot name="button-cancel">
                  </slot>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
</template>