const mqlHover = window.matchMedia('(hover: none)');

const bps = {
  sm: window.matchMedia('(max-width: 639px)'),
  md: window.matchMedia('(min-width: 640px) and (max-width: 1023px)'),
  lg: window.matchMedia('(min-width: 1024px) and (max-width: 1359px)'),
  xl: window.matchMedia('(min-width: 1360px)')
};

const state = {
  size: 'lg',
  menu: 'desktop',
  isTouch: mqlHover.matches
}

// Update Size Per Breakpoint, Listen for Changes

function sizeUpdate(mq) {
  if (mq.media == bps.sm.media && mq.matches) {
    state.size = 'sm';
    state.menu = 'mobile';
  }
  if (mq.media == bps.md.media && mq.matches) {
    state.size = 'md';
    state.menu = 'mobile';
  }
  if (mq.media == bps.lg.media && mq.matches) {
    state.size = 'lg';
    state.menu = 'desktop';
  }
  if (mq.media == bps.xl.media && mq.matches) {
    state.size = 'xl';
    state.menu = 'desktop';
  }
}

Object.keys(bps).forEach(function(size) {
  let mql = bps[size];
  mql.addListener(sizeUpdate);
  sizeUpdate(mql); // Run @ Load, Update Size
});

// Watch State of Touch, in case input device changes

mqlHover.addListener((e) => state.isTouch = (e.matches) ? true : false);

export {state as state}