<script>
  export default {
  }
</script>
<template>
  <svg width="16" height="16" viewBox="0 0 16 16"xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M1.92276 3.85156C0.861055 3.85174 0.000174093 2.98969 2.63748e-08 1.92613C-0.000173948 0.862566 0.860359 0.000174399 1.92206 2.64211e-08C2.98394 -0.000174253 3.84482 0.861868 3.84499 1.92543C3.84516 2.989 2.98463 3.85139 1.92276 3.85156ZM0.263021 16H3.57934V5.31258H0.263021V16ZM12.686 16H15.9987L16.0001 10.1373C16.0001 7.25914 15.3811 5.04677 12.0234 5.04677C10.7398 4.999 9.53399 5.66231 8.88559 6.77295H8.84104V5.31262H5.66081V15.9997H8.97364V10.7129C8.97364 9.31863 9.23746 7.9683 10.9629 7.9683C12.6639 7.9683 12.686 9.56373 12.686 10.803V16Z" fill="currentColor"/>
</svg>

</template>
