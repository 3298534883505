<script>

  export default {
    props: {
      type: {type: String}
    },
  }

</script>

<template>
  
  <!-- POST -->
  
  <article class="swiper-slide" v-if="type ==='post'">
    <a href="http://fr.com" class="slide-content">
      <div class="slide-body">
        <p class="eyebrow">
          <span>Article</span>
          <span>May 27, 2021</span>
        </p>
        <h3 class="slide-title h5 hover">Orci & Praesentes Alterum Nullum et Ante te D.S. Praedam Autem est Virtus Neminern ut Quod-Natoque Sinuni Hilari nisi Eorum Usus Civilatcm V Porro.</h3>
      </div>
      <span class="arrow-link small" aria-label="Click to">Read matter <span class="icon-arrow-forward-small"></span></span>
    </a>
  </article>

  <!-- STAT: Note If link is omitted (on most except home) slide-content should be a <div> -->
  
  <div class="swiper-slide" v-else-if="type ==='stat'">
    <a href="http://fr.com" class="slide-content">
      <div class="statistic">
        <span class="value" aria-describedby="x">18,000</span>
        <span class="h5 efinition hover" id="x">U.S. and foreign patents filed in '20— more than any other firm</span>
      </div>
      <span class="arrow-link small" aria-label="Visit">Optional Link <span class="icon-arrow-forward-small"></span></span>
    </a>
  </div>

  <!-- RECOGNITION -->

  <div class="swiper-slide" v-else-if="type ==='recognition'">
    <div class="slide-content">
      <p class="h4 mb-none">Ranked National "Tier 1" for Patent Prosecution</p>
      <p class="meta mb-none">
        <span>IAM Patent 1000: The World's Leading Patent Practitioners</span>
        <span>2015-2019</span>
      </p>
    </div>
  </div>

  <!-- TESTIMONIAL -->

  <div class="swiper-slide" v-else-if="type ==='testimonial'">
    <blockquote class="slide-content">
      <div>
        <p>
          I refer to her as the judge whisperer. They will always listen to her. She’s one of the best I’ve seen in front of a judge. She owns that courtroom. She should be anyone’s first choice as a first chair trial lawyer. She could do any type of trial, she’s just amazing.
        </p>
      </div>
      <cite>
        <span class="name">Firstname Lastname</span>
        <span class="company small">Company name</span>
      </cite>
    </blockquote>
  </div>

  <!-- BIO -->
  
  <div class="swiper-slide" v-else-if="type ==='bio'">
    <div class="card-bio">
      <figure>
        <a href="#" tab-index="-1"><img src="/images/demo/bio-sample.jpg" alt="" loading="lazy"></a>
      </figure>
      <h3 class="card-bio-title h5"><a href="#">Christina D. Brown-Marshall</a></h3>
      <dl class="meta">
        <dt class="sr-only">Areas of Practice</dt>
        <dd>Practice Support Professional, Litigation</dd>
        <dt class="sr-only">Location</dt>
        <dd>Atlanta</dd>
        <dt class="sr-only">Email</dt>
        <dd><a href="#">brown-marshall@fr.com</a></dd>
        <dt class="sr-only">Phone</dt>
        <dd><a href="#">404-724-2760</a></dd>
      </dl>
    </div>
  </div>
  
  <!-- SERVICE -->
  
  <div class="swiper-slide" v-else-if="type ==='service'">
    <a href="http://fr.com" class="slide-content card-service">
      <div class="slide-body">
        <p class="eyebrow">Litigation services</p>
        <h3 class="h5 slide-title">Appellate litigation</h3>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.</p>
      </div>
    </a>
  </div>  

</template>