<script>
  import _ from 'lodash';

  export default {
    name: "people-search-person-cell",
    props: {
      person: null
    },
    data: function() {
      return {
      };
    },
    computed: {
      imageUrl: function() {
        return this.person.image_url || "https://www.oakwoodinsurance.com/wp-content/uploads/2019/04/headshot-placeholder.jpeg";
      }
    },
    methods: {
    }
  }

</script>

<template>

  <div class="card">
    <h3>{{person.first_name}} {{person.last_name}}</h3>
    <img :src="imageUrl"  loading="lazy"/>
    <ul>
      <li>Services: {{person.services}}</li>
      <li>Industries: {{person.industries}}</li>
      <li>Degrees: {{person.degrees}}</li>
      <li>Languages: {{person.languages}}</li>
      <li>Offices: {{person.offices}}</li>
      <li>email: {{person.email}}</li>
    </ul>
  </div>

</template>