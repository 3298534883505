<script>
  import { state } from './state';
  import _ from 'lodash';
  import TwitterXIcon from '../svg-components/twitter-x.vue';
  import FacebookIcon from '../svg-components/facebook.vue';
  import LinkedInIcon from '../svg-components/linked-in.vue';
  import CloseCircleIcon from '../svg-components/close-circle.vue';
  import CloseSolidIcon from '../svg-components/close-solid.vue';
  import MailIcon from '../svg-components/mail.vue';
  import ShareIcon from '../svg-components/share.vue';

  // @TD Show Download Button Selectively for templates that are ready.
  // FTM it's only ready for the Bios in a different component

  export default {
    name: "page-tools",
    components: {
      'twitter-x-icon': TwitterXIcon,
      'facebook-icon': FacebookIcon,
      'linked-in-icon': LinkedInIcon,
      'close-circle-icon': CloseCircleIcon,
      'close-solid-icon': CloseSolidIcon,
      'mail-icon': MailIcon,
      'share-icon': ShareIcon
    },
    data: function() {
      let config = _.merge({
            download_url: null,
            show_share: true,
            print_url: null,
            subscribe_url: null,
            directions_url: null,
            hide_all_icons: false,
          }, this.options);
      return {
        shareOpen: false,
        pageLoaded: false,
        config: config
      }
    },
    mounted() {
      window.addEventListener("load", (event) => {
        this.pageLoaded = true;
      });
    },
    props: {
      options: {
        type: Object
      }
    },
    computed: {
      fb_url: function() {
        return `https://www.facebook.com/sharer/sharer.php?u=${this.url}`;
      },
      twitter_url: function() {
        return `https://x.com/share?url=${this.url}&text=${this.rawTitle}`;
      },
      linkedin_url: function() {
        return `https://www.linkedin.com/shareArticle?mini=true&url=${this.url}&title=${this.rawTitle}&source=FR.com`;
      },
      email_url: function() {
        return `mailto:?subject=Thought this might interest you...&body=${this.emailBody}`;
      },
      hideAllIcons: function() {
        return this.config.hide_all_icons;
      },
      emailBody: function() {
        let body = '';
        body += this.rawTitle;
        body += '%0d%0a%0d%0a\n\n';
        
        /*
        if(this.description) {
          body += this.rawDescription;
          body += '%0d%0a%0d%0a\n\n';
        }
        */

        body += this.url;
        
        return body;
      },
      url: function() {
        return this.config.url;
      },
      title: function() {
        return this.config.title;
      },
      rawTitle: function() {
        let pageTitle = this.config.title || ''
        return this.encodeTitle(pageTitle);
      },
      description: function() {
        let pageDesc = this.config.description || ''
        return pageDesc.replace(/<\/?[^>]+>/ig, " ");
      },
      rawDescription: function() {
        return this.encodeTitle(this.description);
      },
      getLocationPathName: function() {
        return window.location.pathname;
      },
      showDownload: function() {
        return this.config.download_url && !/featured-matters/.test(this.getLocationPathName)
      },
      showPrint: function() {
        return (this.config.print_url);
      },
      showShare: function() {
        return this.config.show_share;
      },
      showSubscribe: function() {
        return this.config.subscribe_url;
      },
      showDirections: function() {
        return this.config.directions_url;
      },
      showPrinterIcon: function() {
        return false; // Requested removed from non-bios (for now)
        //return !this.flaggedPathNames.some((element)=> this.getLocationPathName.includes(element));
      },
      flaggedPathNames: function() {
        return ['/why-fish/', '/featured-matters/', '/sitemap/'];
      }
    },
    methods: {
      printPage: function() {
        // console.log("Printing page", window);
        window.print();
      },
      encodeTitle(str) {
        str = str.replace(/<\/?[^>]+>/ig, " ");
        str = str.replace(/&amp;/g, '&');
        str = escape(str);
        return str;
      }
    }
  }


</script>

<template>
  <nav class="page-tools" v-if='!hideAllIcons'>
    <ul aria-label="Page Tools">
      <li v-if="config.directions_url"><a :href="config.directions_url" target="_blank" class="get-directions-url" ><span class="icon-map"></span>&nbsp;Get directions</a></li>
      <!--
      <li class="download" v-if="showDownload">
        <a :href="this.config.download_url">
          <span class="icon-download-pdf" aria-hidden="true"></span>
        </a>
      </li>
      -->
      <li class="print" v-if="showPrinterIcon && pageLoaded">
        <button aria-label="Print This Page" @click="printPage"> 
          <span class="sr-only">Print Page</span>
          <span class="icon-printer" aria-hidden="true"></span>
        </button>
      </li>
      <li v-if="showShare" class="sharing">
        <button
          aria-label="Toggle Page Sharing Options"
          @click="shareOpen = !shareOpen"
          class="toggle-share"
          aria-haspopup="true"
          :aria-expanded="shareOpen ? 'true' : 'false'"
          aria-controls="shareElements"          
        >
          <span class="circle-icon hover-icon" v-if="shareOpen" aria-hidden="true">
            <span class="off"><close-circle-icon/></span>
            <span class="on"><close-solid-icon /></span>
          </span>
          <span aria-hidden="true" v-else>
            <share-icon />
          </span>

        </button>
        <ul aria-label="Share This Page" class="social" :class="{active : shareOpen}" id="shareElements">
          <li aria-label="Facebook">
            <a target="_blank" :href="fb_url">
              <span aria-hidden="true"><facebook-icon /></span>
            </a>
          </li>
          <li aria-label="Twitter">
            <a  target="_blank" :href="twitter_url">
              <span aria-hidden="true"><twitter-x-icon /></span>
            </a>
          </li>
          <li aria-label="Email">
            <a target="_blank" :href="email_url" >
              <span aria-hidden="true"><linked-in-icon /></span>
            </a>
          </li>
        </ul>
      </li>
       <li class="subscribe" v-if="showSubscrib">
         <a :href="this.config.subscribe_url">
          <span aria-hidden="true"><mail-icon /></span>
        </a>
      </li>
    </ul>
  </nav>

</template>
