<script>
  export default {
  }
</script>
<template>
  <svg width="19" height="20" viewBox="0 0 19 20" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.002 18C13.9343 18 13.0644 17.161 13.0644 16.13C13.0644 15.099 13.9343 14.259 15.002 14.259C16.0697 14.259 16.9386 15.099 16.9386 16.13C16.9386 17.161 16.0697 18 15.002 18ZM4.97575 11.62C4.05096 12.135 2.86417 11.828 2.32981 10.935C1.79647 10.042 2.1144 8.89598 3.03919 8.37998C3.96397 7.86498 5.1518 8.17098 5.68513 9.06398C6.2195 9.95798 5.90157 11.104 4.97575 11.62ZM15.002 2C16.0697 2 16.9386 2.839 16.9386 3.871C16.9386 4.902 16.0697 5.741 15.002 5.741C13.9343 5.741 13.0644 4.902 13.0644 3.871C13.0644 2.839 13.9343 2 15.002 2ZM15.0022 12.259C13.8973 12.259 12.8948 12.693 12.1689 13.394L7.87736 11.003C8.05548 10.362 8.0472 9.679 7.86804 9.019L12.1803 6.615C12.9052 7.31 13.9024 7.741 15.0022 7.741C17.2122 7.741 19.01 6.005 19.01 3.871C19.01 1.736 17.2122 0 15.0022 0C12.7923 0 10.9935 1.736 10.9935 3.871C10.9935 4.222 11.0587 4.556 11.1498 4.88L6.8397 7.282C5.5887 6.069 3.61071 5.752 2.00347 6.648C0.0896938 7.715 -0.568944 10.087 0.536035 11.935C1.64101 13.783 4.09744 14.419 6.01122 13.352C6.3219 13.179 6.58598 12.962 6.8283 12.728L11.1457 15.134C11.0566 15.453 10.9935 15.783 10.9935 16.13C10.9935 18.264 12.7923 20 15.0022 20C17.2122 20 19.01 18.264 19.01 16.13C19.01 13.995 17.2122 12.259 15.0022 12.259Z" fill="currentColor"/>
  </svg>

</template>
