<script>
  export default {
  }
</script>
<template>
  <svg width="7" height="16" viewBox="0 0 7 16" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.71812 7.98459H4.64185V16H1.49612V7.98459H0V5.16766H1.49612V3.34478C1.49612 2.04123 2.08374 0 4.66985 0L7 0.0102728V2.74459H5.30932C5.032 2.74459 4.64205 2.89059 4.64205 3.51242V5.17028H6.99295L6.71812 7.98459Z" fill="currentColor"/>
  </svg>

</template>
