<script>
  export default {
    data: ()=>({
      showModal: false,
    }),
    mounted: function() {
      document.addEventListener('click', (event) => {
        this.url = event.target.getAttribute('href') || '';
        if (this.isEmail()) {
          event.preventDefault()
          this.toggleModal();
        }
      });
    },
    methods: {
      isEmail() {
        return /^mailto:(.*)/.test(this.url);
      },
      toggleModal() {
        this.showModal = !this.showModal;
        if(this.showModal) {
          document.body.classList.add('modal-open');
        }else{
          document.body.classList.remove('modal-open');
        }
      },
      handleMailto() {
        window.location.href = this.url
      }
    }
  }
</script>

<template>
  <div class="mailto-modal">
    <modal :showModal="showModal" :toggleModal="toggleModal">
      <div slot="body" v-html="'Unsolicited e-mails and information sent to Fish & Richardson P.C. will not be considered confidential and do not create an attorney-client relationship with Fish & Richardson P.C. or any of our attorneys. Furthermore, these communications and materials may be disclosed to others and may not receive a response. If you are not already a client of Fish & Richardson P.C., do not include any confidential information in this message'"></div>
      <button slot="button-ok" type="button" class="agree-button" @click="handleMailto">
        Ok, I understand
      </button>
      <button slot="button-cancel" type="button" class="cancel-button" @click="toggleModal">
        Cancel my request
      </button>
    </modal>
    </div>
</template>