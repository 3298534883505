<script>
export default {

  data: ()=>({
    isCopied: false,
    isActive: false,
  }),
  props: {
    address: String,
  },
  methods: {
    copy: function () {
      navigator.clipboard.writeText(this.address)
        .then(() => {
          this.isCopied = true;
          this.isActive = true;
          setTimeout(() => {
            this.isActive = false;
            this.isCopied = false;
          }, 4000);
        });
    }
  },
  computed: {
    getCopyLabel: function() {
      return this.isCopied ? 'Copied!' : 'Copy to clipboard';
    }
  }
}
</script>
<template>
   <button class="btn-clipboard" :class="{'active' : isActive}" @click="copy" >
      <span class="icon-link"><span class="sr-only" role="tooltip" aria-live="polite">{{getCopyLabel}}</span></span>
      <span aria-hidden="true" class="tooltip-copy" :class="{'is-copied' : isCopied}">
        <span class="icon icon-checkmark-circle-solid"></span>
        <span>{{getCopyLabel}}</span>
      </span>
    </button>
</template>